import styled from '@xstyled/styled-components'

export const BrandContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0px 10px;
  justify-content: center;
  align-content: middle;
  img {
    transition: opacity 0.2s ease-in-out;
    object-fit: contain;
    opacity: 0.4;
    max-width: 92px;
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
`
export const BrandPaginationMobileWrap = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
export const BrandPaginationMobile = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  position: fixed;
  bottom: 50px;
  height: 100px;
  left: 50%;
  transform: translateX(-50%);
`
export const BrandWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const BrandImage = styled.img<{ active: boolean }>`
  opacity: ${(props) => props.active && 1}!important;
`
export const BrandPagination = styled.div<{ maxWidth: string }>`
  max-width: ${(props) => props.maxWidth};
  position: fixed;
  bottom: 50px;
  width: 100vw;
  height: 100px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
`

export const LineIndicatorMobile = styled.div`
  width: 100%;
  position: relative;
  height: 1px;
  margin: 20px 0;
`

export const LineIndicator = styled.div`
  width: 100%;
  position: absolute;
  height: 1px;

  right: 0;
`

export const LineIndicatorInner = styled.div`
  background-color: white;
  width: 92px;
  height: 4px;
  opacity: 1;
  position: absolute;
  top: -3px;
  transform: scaleX(0);
`
export const LineIndicatorContainer = styled.div`
  width: 150;
  justify-content: center;
  display: flex;
`

export const LineBackground = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: calc(100% - 60px);
`
