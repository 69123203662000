import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { pageFadeInOut } from '@/modules/animationVariants'
import ClientCarousel from '@/components/ClientCarousel'
import { RightOnSmaller } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import {
  APP_SWITCH_BACKGROUND_THREE_SCENE,
  APP_PAGE_ANIMATION_COMPLETED,
} from '@/events'
import PubSub from 'pubsub-js'
import { TweenMax } from 'gsap'

export const query = graphql`
  query {
    allCmsCase(filter: { status: { eq: "published" } }) {
      edges {
        node {
          id
          slug
          type
          title
          pretitle
          subtitle
          without_followup
          client_quote_gender
          client_header
          client_text
          client_footer
          article
          title_width
          type
          logo {
            data {
              url
            }
          }
          overview_image {
            data {
              url
            }
          }
        }
      }
    }
  }
`
/* eslint-disable camelcase */

const transformRecord = ({ node }) => ({
  id: node.id,
  route: `/clients-and-cases/cases/${node.slug}`,
  pretitle: node?.pretitle,
  logo: node?.logo?.data?.url,
  title: node.title,
  text: node?.subtitle,
  slug: node.slug,
  nameCompany: node?.client_name,
  video: node?.video?.data?.url,
  gender: node?.client_quote_gender,
  overviewImage: node?.overview_image,
  noFollowup: node?.without_followup,
  maxWidth: (node?.title_width && `${node?.title_width}rem`) || '110rem',
})

/* eslint-enable camelcase */
interface Cases {
  allCmsCase: GatsbyTypes.CmsCaseConnection
}

const Index: React.FC<{ data: Cases }> = ({ data }) => {
  const [activeItem, setActiveItem] = useState(0)
  const clients = data.allCmsCase.edges.map(transformRecord).reverse()
  const learnMoreRef = useRef(null)

  const goTo = (selectedId) => {
    setActiveItem(selectedId % clients.length)
  }

  const onNext = (e) => {
    const target = e.target as HTMLElement
    const targetTag = target.tagName
    if (
      targetTag === 'path' ||
      targetTag === 'svg' ||
      target.dataset.url ||
      (target.className &&
        typeof target.className === 'string' &&
        target.className.includes('noMove'))
    ) {
      return
    }
    setActiveItem((newActiveItem) => (newActiveItem + 1) % clients.length)
  }

  useEffect(() => {
    const client = clients[activeItem]
    if (client.overviewImage) {
      PubSub.publish(APP_SWITCH_BACKGROUND_THREE_SCENE, {
        name: `${client.slug}_scene`,
        pageReadyDelay: 3,
        mediaSettings: {
          mediaHTMLElement: client?.overviewImage?.data?.url,
          // position: { x: 0.75, y: 0.5 },
        },
      })
    } else {
      const sceneName = { w: 'femalehead', m: 'malehead' }[client.gender]
      PubSub.publish(APP_SWITCH_BACKGROUND_THREE_SCENE, {
        sceneName,
      })
      PubSub.publish(APP_PAGE_ANIMATION_COMPLETED)
    }

    if (!client.noFollowup) {
      TweenMax.fromTo(
        learnMoreRef.current,
        0.5,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 1,
        },
      )
    }
  }, [activeItem])

  const addDomEvents = () => {
    document
      .getElementById('___gatsby')
      .addEventListener('mousedown', (e) => onNext(e))
  }

  const removeDomEvents = () => {
    document
      .getElementById('___gatsby')
      .removeEventListener('mousedown', (e) => onNext(e))
  }

  useLayoutEffect(() => {
    addDomEvents()

    return () => {
      removeDomEvents()
    }
  }, [])
  return (
    <>
      <RightOnSmaller ref={learnMoreRef}>
        {clients[activeItem] && !clients[activeItem].noFollowup && (
          <SideNavigationLink
            variant="light"
            position="right"
            delay={1.1}
            time={1}
            url={clients[activeItem]?.route}
            text="learn more"
          />
        )}
      </RightOnSmaller>
      <Page
        content={
          <PageWrapper>
            <ClientCarousel
              onGoTo={goTo}
              activeClient={activeItem}
              clients={clients}
            />
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#5a7d69',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default Index
