import React, { useRef, useEffect, useState } from 'react'
import Quote from '../Quote'
import { useContentInProp } from '@/hooks/useContentInProp'
import {
  BrandPagination,
  BrandImage,
  BrandContainer,
  LineIndicator,
  LineIndicatorInner,
  LineIndicatorContainer,
  LineBackground,
  BrandPaginationMobile,
  BrandPaginationMobileWrap,
  BrandWrap,
  LineIndicatorMobile,
} from './styled'
import gsap from 'gsap'
import { getIsMobile } from '@/modules/utils/getMobile'
import { Box } from '@xstyled/styled-components'
import { Text } from '@/components/Text'
import ThreeReadyOnMount from '@/modules/utils/ThreeReadyOnMount'

interface clientProps {
  id: string
  title: string
  text: string
  logo: string
  nameCompany: string
  maxWidth: string
  pretitle: string
  route: string
  slug: string
  noFollowup: boolean
  gender: 'male' | 'female'
  video: string
}

const Index: React.FC<{
  clients: clientProps[]
  activeClient: number
  onGoTo: (newSelection) => void
}> = ({ clients, activeClient, onGoTo }) => {
  const inProp = useContentInProp()
  const timelineRef = useRef(null)
  const [isMobile, setIsMobile] = useState(getIsMobile())
  const innerContainerRef = useRef(null)
  const LineIndicatorInnerRef = useRef(null)
  const setupNewTimeLineMobile = () => {
    if (!LineIndicatorInnerRef.current) {
      return
    }
    timelineRef.current = gsap.timeline({
      delay: 1,
      onComplete: () => {
        onGoTo(activeClient + 1)
      },
    })
    gsap.set(LineIndicatorInnerRef.current, {
      transformOrigin: 'left',
      x: activeClient * 150,
    })
    gsap.to(innerContainerRef.current, {
      transformOrigin: 'left',
      x: activeClient * -75,
    })
    timelineRef.current.fromTo(
      LineIndicatorInnerRef.current,
      8,
      { scaleX: 1 },
      { scaleX: 0, ease: 'sine.in' },
    )
  }
  const setupNewTimeLine = () => {
    if (!LineIndicatorInnerRef.current) {
      return
    }
    timelineRef.current = gsap.timeline({
      delay: 1,
      onComplete: () => {
        onGoTo(activeClient + 1)
      },
    })
    gsap.set(LineIndicatorInnerRef.current, {
      transformOrigin: 'left',
      x: activeClient * 150,
    })
    timelineRef.current.fromTo(
      LineIndicatorInnerRef.current,
      8,
      { scaleX: 1 },
      { scaleX: 0, ease: 'sine.in' },
    )
  }
  useEffect(() => {
    const onResize = () => setIsMobile(getIsMobile())
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.kill()
    }

    if (activeClient < clients.length) {
      if (!isMobile) {
        setupNewTimeLine()
      } else {
        setupNewTimeLineMobile()
      }
    }
    return () => {
      if (timelineRef.current) {
        timelineRef.current.kill()
      }
    }
  }, [activeClient])

  return (
    <>
      <Box row>
        {clients.map((client, index) => {
          const { title, maxWidth, text, pretitle } = client
          return (
            <>
              {activeClient === index && (
                <>
                  <div>
                    <Quote
                      variant="light"
                      maxWidth={maxWidth}
                      inProp={inProp}
                      noSeperator
                      titleComponent
                      preTitle={pretitle}
                    >
                      {title}
                    </Quote>
                    <ThreeReadyOnMount>
                      <Box col={{ xs: 1, md: 0.6, lg: 0.5 }}>
                        <Text text={text} />
                      </Box>
                    </ThreeReadyOnMount>
                  </div>
                  {isMobile && (
                    <ThreeReadyOnMount>
                      <Text
                        isMobile
                        links={[
                          {
                            link: 'learn more',
                            url: clients[activeClient]?.route || '#',
                            variant: 'all',
                          },
                        ]}
                      />
                    </ThreeReadyOnMount>
                  )}
                </>
              )}
            </>
          )
        })}
      </Box>
      {isMobile && (
        <BrandPaginationMobile>
          <BrandPaginationMobileWrap
            ref={innerContainerRef}
            width={`${clients.length * 150}px`}
          >
            <LineIndicatorMobile>
              <LineBackground />
              <LineIndicatorContainer>
                <LineIndicatorInner ref={LineIndicatorInnerRef} />
              </LineIndicatorContainer>
            </LineIndicatorMobile>
            <BrandWrap>
              {clients.map((client, index) => (
                <BrandContainer
                  key={client.route}
                  onClick={() => onGoTo(index)}
                >
                  <BrandImage
                    active={index === activeClient}
                    alt="logo"
                    src={client.logo}
                  />
                </BrandContainer>
              ))}
            </BrandWrap>
          </BrandPaginationMobileWrap>
        </BrandPaginationMobile>
      )}
      {!isMobile && (
        <BrandPagination maxWidth={`${clients.length * 150}px`}>
          <LineIndicator>
            <LineBackground />
            <LineIndicatorContainer>
              <LineIndicatorInner ref={LineIndicatorInnerRef} />
            </LineIndicatorContainer>
          </LineIndicator>

          {clients.map((client, index) => (
            <BrandContainer key={client.route} onClick={() => onGoTo(index)}>
              <BrandImage
                active={index === activeClient}
                alt="logo"
                src={client.logo}
              />
            </BrandContainer>
          ))}
        </BrandPagination>
      )}
    </>
  )
}

export default Index
