import React, { useEffect } from 'react'

import PubSub from 'pubsub-js'
import { APP_THREE_PAGE_READY, APP_PAGE_CHANGE_THEME } from '@/events'
//
// @ts-ignore
const ThreeReadyOnMount: React.FC<{}> = ({ children }) => {
  useEffect(() => {
    PubSub.publish(APP_THREE_PAGE_READY, {})
  }, [])
  return children
}

export default ThreeReadyOnMount
